import close from "./close";
import full from "./full";
import fullHeader from "./fullHeader";
import header from "./header";
import login from "./login";
import title from "./title";

export default `
  ${full}
  ${fullHeader}
  ${header}
  ${close}
  ${login}
  ${title}
`;
