import bar from "./bar";
import drawer from "./drawer";
import header from "./header";
import overlay from "./overlay";

export default `
  ${bar}
  ${drawer}
  ${header}
  ${overlay}
`;
