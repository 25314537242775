import Properties from "./Properties";
import Metadata from "./Metadata";
import Wrapper from "./Wrapper";
import Collaborators from "./Collaborators";
import Ingestion from "./ingestion";
import Styles from "./Styles";
import Analytics from "./Analytics";

export default {
  Properties,
  Metadata,
  Wrapper,
  Collaborators,
  Ingestion,
  Styles,
  Analytics
};
